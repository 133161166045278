.MapMarker:hover {
  filter: invert(.5) sepia(1);
}

.Marker-desc {
  display: inline-block;
  padding: 1rem 3rem;
  color: white;
  font-size: 2em;
  background-color: #9b4dca;
  min-height: 200px;
  width: fit-content;
  text-align: left;
}

.name {
  text-align: center;
}

.details {
  margin-top: 2px;
}

.desc-row {
  justify-content: space-between;
}

.desc-row #first {
  margin-right: 12px;
}
.form {
  margin: 0 2rem;
  text-align: left;
}

.form .locate {
  justify-content: center;
}

.form-map-container {
  width: 100%;
}

.marker {
  cursor: pointer;
  color: white;
  padding: 15px 10px;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

.icon {
  display: inline-table;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
}

.triangle {
  min-height: 15px;
  min-width: 15px;
  max-height: 15px;
  max-width: 15px;
}
.App {
  text-align: center;
  height: 100%;
  overflow-x: hidden;
}

.hero {
  height: 250px;
  background-position: center;
  background-size: cover;
  background-image: url(/toiletpaper.jpg);
  width: 100%;
}

.header {
  position: relative;
  width: fit-content;
  margin: 40px auto 40px;
}

@media screen and (max-width: 1000px) {
  .row#first-row {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 599px) {
  .header {
    font-size: 36px;
  }
}

.header::after {
  position: absolute;
  width: 100%;
  height: 12px;
  bottom: -5px;
  left: 0;
  content: "";
  background-color: rgba(150, 0, 125, 0.2);
}

.big-button {
  font-size: 18px;
  margin: 10px 20px;
  max-width: 100%;
  word-break: break-word;
  white-space: normal;
  height: auto;
  padding: 10px 20px;
}

@media screen and (min-width: 600px) {
  .big-button {
    font-size: 24px;
    min-width: 400px;
  }
}

.footer {
  margin: 2rem auto 1rem;
  bottom: 0;
  width: 100%;
  height: 40px;
}

.FrontPage p {
  font-size: 18px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}

.FrontPage h2 {
  margin-top: 4rem;
}

.testimonial-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: 2rem auto;
}

#minna-picture {
  max-width: 200px;
}

h3 {
  color: #9b4dca;
}

iframe {
  max-width: 100%;
}

.mapContainer {
  height: calc(100vh - 135px);
  width: 100%;
}
